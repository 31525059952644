import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import React from "react";
import * as TUI from "theme-ui";
import { Markdown } from "../Markdown";
import { cookieConsent } from "./data";

const Root = TUI.Box;

export interface Props extends React.ComponentPropsWithoutRef<typeof Root> {}

const localStorageKey = "cookie-consent";

function CookieConsent(props: Props, ref: React.Ref<HTMLDivElement>) {
  const { sx, ...rest } = props;
  const { locale } = useRouter();

  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    try {
      if (localStorage.getItem(localStorageKey) !== cookieConsent?.version) {
        setIsVisible(true);
      }
    } catch (e) {
      /*
       * Whatever
       */
    }
  }, [setIsVisible]);

  const dismiss = () => {
    setIsVisible(false);

    try {
      localStorage.setItem(localStorageKey, cookieConsent?.version!);
    } catch (e) {
      /*
       * Whatever
       */
    }
  };

  const info = cookieConsent?._allInfoLocales?.find((x) => x?.locale === locale);

  const accept = cookieConsent?._allAcceptLocales?.find((x) => x?.locale === locale);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          style={{
            zIndex: 999,
            position: "fixed",
            bottom: 0,
            left: 0,
          }}
          initial={{ opacity: 0, y: 50 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { delay: 1, duration: 0.4 },
          }}
          exit={{ opacity: 0, y: 200 }}
        >
          <Root
            ref={ref}
            {...rest}
            sx={{
              margin: "8px",
              maxWidth: ["100%", "440px"],
              bg: "black",
              color: "white",
              p: [3, 4],
              boxShadow: "elevation3",
              "@media print": {
                display: "none",
              },
              ...sx,
            }}
          >
            <TUI.Text
              sx={{
                mb: [3, 4],
                "& a": {
                  color: "white",
                  textDecoration: "underline",
                },
              }}
              variant="f5"
            >
              <Markdown variant="markdown.main">{info?.value}</Markdown>
            </TUI.Text>

            <TUI.Button variant="buttons.ghost" onClick={dismiss} sx={{ color: "currentColor", width: "100%" }}>
              {accept?.value}
            </TUI.Button>
          </Root>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default React.forwardRef(CookieConsent);
